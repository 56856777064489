const sheben = [
    {
        name : 'БЕТОН М - 100',
        price: 'Цена 4650 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 150',
        price: 'Цена 4850 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 200',
        price: 'Цена 5050 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 250',
        price: 'Цена 5400 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 300',
        price: 'Цена 5750 ₽ за 1 м3'
    },
];

const gravel = [
    {
        name : 'БЕТОН М - 200',
        price: 'Цена 6500 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 250',
        price: 'Цена 6850 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 300',
        price: 'Цена 7150 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 350',
        price: 'Цена 7500 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 400',
        price: 'Цена 7850 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 450',
        price: 'Цена 8200 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 500',
        price: 'Цена 8550 ₽ за 1 м3'
    },
    {
        name : 'БЕТОН М - 550',
        price: 'Цена 8900 ₽ за 1 м3'
    },
]

const rastvor = [
    {
        name : 'РАСТВОР М - 50',
        price: 'Цена 4500 ₽ за 1 м3'
    },
    {
        name : 'РАСТВОР М - 100',
        price: 'Цена 4650 ₽ за 1 м3'
    },
    {
        name : 'РАСТВОР М - 150',
        price: 'Цена 5050 ₽ за 1 м3'
    },
    {
        name : 'РАСТВОР М - 200',
        price: 'Цена 5250 ₽ за 1 м3'
    },
    {
        name : 'ИЗВЕСТКОВЫЙ',
        price: 'Цена 4100 ₽ за 1 м3'
    },
]

const priceList = [sheben, gravel, rastvor];

const calcPriceList = [
    {
        name : 'БЕТОН М - 100 ЩЕБЕНЬ',
        price: 4650
    },
    {
        name : 'БЕТОН М - 150 ЩЕБЕНЬ',
        price: 4850
    },
    {
        name : 'БЕТОН М - 200 ЩЕБЕНЬ',
        price: 5050
    },
    {
        name : 'БЕТОН М - 250 ЩЕБЕНЬ',
        price: 5400
    },
    {
        name : 'БЕТОН М - 300 ЩЕБЕНЬ',
        price: 5750
    },
    {
        name : 'БЕТОН М - 200 ГРАВИЙ',
        price: 6500
    },
    {
        name : 'БЕТОН М - 250 ГРАВИЙ',
        price: 6850
    },
    {
        name : 'БЕТОН М - 300 ГРАВИЙ',
        price: 7150
    },
    {
        name : 'БЕТОН М - 350 ГРАВИЙ',
        price: 7500
    },
    {
        name : 'БЕТОН М - 400 ГРАВИЙ',
        price: 7850
    },
    {
        name : 'БЕТОН М - 450 ГРАВИЙ',
        price: 8200
    },
    {
        name : 'БЕТОН М - 500 ГРАВИЙ',
        price: 8550
    },
    {
        name : 'БЕТОН М - 550 ГРАВИЙ',
        price: 8900
    },
    {
        name : 'РАСТВОР М - 50',
        price: 4500
    },
    {
        name : 'РАСТВОР М - 100',
        price: 4650
    },
    {
        name : 'РАСТВОР М - 150',
        price: 5050
    },
    {
        name : 'РАСТВОР М - 200',
        price: 5250
    },
    {
        name : 'РАСТВОР ИЗВЕСТКОВЫЙ',
        price: 4100
    },
]

export {priceList, calcPriceList};