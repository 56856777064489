<template>
  <section class="prices">
    <div class="_container">
      <h2><span>БЕТОН</span> ДЛЯ ЛЮБЫХ ЦЕЛЕЙ</h2>
      <div class="tabs">
        <div class="tabs__controls">
          <button class="btn" @click="activeIdx=0" >ТЯЖЕЛЫЕ БЕТОНЫ НА ЩЕБНЕ</button>
          <button class="btn" @click="activeIdx=1" >ТЯЖЕЛЫЕ БЕТОНЫ НА ГРАВИИ</button>
          <button class="btn" @click="activeIdx=2" >РАСТВОРЫ</button>
        </div>
        <div class="tabs__content">
          <div class="tab__item" v-for="item in activeTab" >
            <p class="name">{{item.name}}</p>
            <p class="price">{{item.price}}</p>
            <button class="btn order" @click="scrollToBottom">ЗАКАЗАТЬ</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {priceList} from '@/utils/prices'
import {ref, computed} from "vue"
export default {
  setup() {
    const activeIdx = ref(0);
    const activeTab = computed(() => {
      return priceList[activeIdx.value];
    })
    const scrollToBottom = () => {
      window.scrollTo(0, document.body.scrollHeight);
    }
    return {activeIdx, activeTab, scrollToBottom};
  },
};
</script>

<style scoped>
.prices {
  background: #fff;
  height: auto;
}
h2 {
  color: #000;
  font-size: 55px;
  font-weight: 500;
  line-height: 120%;
  margin: 20px 0;
}
h2 span {
  color: #c63636;
}
.tabs {
  display: flex;
  width: 100%;
}

.name{
  width: 200px;
}
.tabs__controls {
  width: 30%;
  padding-right: 5px;
}
.tabs__controls > *:not(:last-child) {
  margin-bottom: 15px;
}
.tabs__controls .btn {
  width: 100%;
  min-height: 56px;
}
.tabs__content {
  width: 70%;
  padding-left: 5px;
}
.tab__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  text-align: center;
  font-family: Comfortaa;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
  min-height: 50px;
}
.tab__item:not(:last-child) {
  margin-bottom: 15px;
}
.tab__item:nth-child(2n + 1) {
  background: rgba(198, 54, 54, 0.29);
}
.tab__item:nth-child(2n) {
  background: rgba(243, 219, 219, 0.29);
}
@media (max-width: 1000px) {
  .tabs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .tabs__controls {
    display: flex;
    width: 100%;
    gap: 10px;
    padding-right: 0;
  }
  .tabs__controls > *:not(:last-child) {
    margin-bottom: 0px;
  }
  .tabs__controls .btn {
    width: 100%;
    height: 56px;
  }
  .tabs__content {
    width: 100%;
    padding-left: 0px;
  }
  .tab__item {
    padding-left: 5px;
  }
}
@media (max-width: 680px) {
  h2 {
    font-size: 40px;
    text-align: center;
  }
  .tabs__controls {
    flex-direction: column;
  }
  .tabs__controls .btn {
    font-size: 16px;
    width: 100%;
    height: auto;
  }
  .tab__item {
    padding-right: 5px;
    height: auto;
    font-size: 17px;
    /* justify-content: center; */
    gap: 15px;
  }
  .tab__item .btn {
    font-size: 16px;
    width: 90px;
    /* display: none; */
  }
}
@media (max-width: 460px) {
  .tab__item .btn {
    display: none;
  }
  .tab__item{
    justify-content: center;
    gap: 15px;
  }
}
</style>
